.Home{
    width: 100%;
    position: relative;
    background-color: aliceblue !important;
    nav{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 99;
        position: absolute;
        top: 0;
        left: 0;
        padding: 1em 2.5em 1em 2em;
        @media screen and (max-width:500px){
            padding: 1em .8em;
        }
        img{
            width: 100px;
            @media screen and (max-width:500px){
                width: 70px;
            }
        }
        div{
            display: flex;
            align-items: center;
            column-gap: 10px;
            @media screen and (max-width:500px){
                column-gap: 5px;
            }
            a{
                background-color: white;
                text-decoration: none;
                padding: .5em 2.8em;
                color: $main;
                border-radius: 50px;
                font-size: 1.05em;
                text-align: center;
                @media screen and (max-width:500px){
                    font-size: .9em;
                    padding: .5em 2em;
                }
                &:nth-child(1){
                    background-color: $sub;
                }
            }
        }
    }
    .Hero{
        background:linear-gradient(rgb(6 13 26), rgb(0 0 0 / 75%)), url("https://images.pexels.com/photos/16423534/pexels-photo-16423534/free-photo-of-men-praying-at-the-ali-kuscu-mosque-at-istanbul-airport.jpeg");
        position: relative;
        height: 100vh;
        top: 0;
        width: 100%;
        background-size: cover;
        background-position: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1{
            color: $sub;
            font-size: 4em;
            margin-bottom: 0;
            @media screen and (max-width:500px){
                padding-right: .5em;   
                font-size: 3em; 
            }
        }
        h2{
            color: white;
            @media screen and (max-width:500px){
                padding-right: 1.5em;
                font-size: 1.2em;
            }
        }
        a{
            width: 100%;
            max-width: 260px;
            background-color: $sub;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: .8em 1em;
            border-radius: 50px;
            display: flex;
            text-decoration: none;
            column-gap: 5px;
            color: $main;
            font-size: 1.1em;
            svg{
                font-size: 1.3em;
            }
        }
    }
    .Masajed{
        width: 100%;
        padding: 1em 2em;
        position: relative;
        overflow-x: hidden;
        
        @media screen and (max-width:500px){
            padding: 1em;
        }
        & > div{
            display: flex;
            flex-direction: column;
            
            &>h1{
                font-size: 4.5em;
                margin: 0;
                margin-top: 35px;
                color: $main;
                text-align: center;
                margin-bottom: 20px;
                @media screen and (max-width:500px){
                    font-size: 2em;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
            .Search{
                display: flex;
                font-size: 3em;
                @media screen and (max-width:500px){
                    font-size: 2em;
                    margin-bottom: 10px;
                    padding: 0.4em 0.8em 0.4em 0.6em;
                }
                background-color: $main;
                border-radius: 50px;
                padding: .3em .5em;
                margin-bottom: 20px;
                input{
                    flex-grow: 1;
                    background-color: transparent;
                    outline: none;
                    border: none;
                    font-size: .5em;
                    color: white;
                }
                svg{
                    font-size: .8em;
                    color: $sub;
                }
            }
            .masajedHolder{
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-evenly;
                row-gap: 10px;
                column-gap: 10px;
                font-size: .9em;
                a{
                    text-decoration: none;
                    width: 300px;
                    background-color: $main;
                    border-radius: 50px;
                    padding: .4em 1.7em;
                    position: relative;
                    flex-grow: 1;
                    div{
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        column-gap: 10px;
                        color: white;
                        h3{
                            @media screen and (max-width:500px){
                                margin: 0;
                            }
                        }
                        svg{
                            color: $sub;
                            @media screen and (max-width:500px){
                                width: 30px;
                            }
                        }
                        &::before{
                            content: '';
                            display: block;
                            left: 1em;
                            width: 15px;
                            height: 15px;
                            z-index: 9;
                            background-color:$sub;
                            position: absolute;
                            border-radius: 50px;
                        }
                        &:after{
                            content: '';
                            width: 15px;
                            height: 15px;
                            background-color:transparent;
                            position: absolute;
                            left: 1em;
                            border-radius: 50px;
                            border: 5px solid $sub;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                        }
                    }
                }
                p{
                    font-size: 1.5em;
                    color: $main;
                }
            }
            & > span {
                text-align: center;
                width: 100%;
                display: block;
                margin-top: 1.5em;
                font-size: 1.3em;
                color: $main;
            }
        }
    }
    .AboutTheClock{
        width: 100%;
        padding: 1em 2em;
        @media screen and (max-width:500px){
            padding: .5em .3em;
        }
        div{
            background: linear-gradient($main, rgb(3 10 22 / 75%)), url("https://images.pexels.com/photos/3624566/pexels-photo-3624566.jpeg");
            background-size: cover;
            background-position: 100% 45%;
            border-radius: 28px;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3em 2em 5em;
            @media screen and (max-width:500px){
                padding: 3em 2em 3.6em;
                border-radius: 20px;
            }
            img{
               width: 200px;
            }
            p{
                color: white;
                font-size: 1.8em;
                text-align: center;
                max-width: 1000px;
                line-height: 2em;
                @media screen and (max-width:500px){
                   font-size: 1em;
                   text-align: justify;
                }
            }
            span{
                display: flex;
                width: 100%;
                max-width: 1000px;
                column-gap: 20px;
                @media screen and (max-width:500px){
                    column-gap: 10px;
                }
                a {
                    flex-grow: 1;
                    text-decoration: none;
                    background-color: white;
                    text-align: center;
                    padding: .9em ;
                    border-radius: 50px;
                    color: $main;
                    @media screen and (max-width:500px){
                        padding: .5em;
                    }
                    &:nth-child(1){
                        background-color: $sub;
                    }
                }
            }
        }
    }
    .AboutForSEO{
        width: 100%;
        padding: 1em 2em;
        row-gap: 20px;
        @media screen and (max-width:500px){
            padding: .5em .3em;
            row-gap: 20px;
        }
        display: flex;
        flex-direction: column;
        .responsiveDesign{
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;
            column-gap: 20px;
            @media screen and (max-width:500px){
                flex-wrap: wrap;
                row-gap: 20px;
            }
            div{
                h1{
                    color: $main;
                    font-size: 3em;
                    padding:.3em .5em;
                    margin-top: 0;
                    background-color: $sub;
                    text-align: center;
                    border-radius: 50px;
                    @media screen and (max-width:500px){
                        font-size: 1.5em;
                    }
                }
                p{
                    font-size: 1.5em;
                    text-align: center;
                    margin-bottom: 2.3em;
                    @media screen and (max-width:500px){
                        font-size: 1.3em;
                        padding: 0 1em;
                        margin-bottom: 1em;
                    }
                }
                a{
                    background-color: $main;
                    width: 100%;
                    max-width: 400px;
                    display: block;
                    margin: 0 auto;
                    color: white;
                    text-decoration: none;
                    text-align: center;
                    border-radius: 50px;
                    padding: .5em;
                    font-size: 1.5em;
                    @media screen and (max-width:500px){
                        font-size: 1.1em;
                    }
                }
            }
            img{
                outline: none;
                border: none;
                width: 100%;
                max-width: 800px;
                border-radius: 20px;
                flex-grow: 1;
                cursor: pointer;
                border: 20px solid $main;
            }
        }
        .optimized{
            width: 100%;
            padding: 3em 2em;
            background-color: $main;
            border-radius: 20px;
            background: linear-gradient($main, rgb(3 10 22 / 75%)), url("https://images.unsplash.com/photo-1490530994484-73efcf105faf");
            background-size: cover;
            background-position: 100% 45%;
            border-radius: 28px;
            background-repeat: no-repeat;
            @media screen and (max-width:500px){
                padding: 3em 1.5em;
            }
            h1{
                text-align: center;
                font-size: 3em;
                color: white;
                margin: 0;
                @media screen and (max-width:500px){
                    font-size: 1.8em;
                    margin-bottom: .2em;
                }
            }
            h2{
                text-align: center;
                display: flex;
                justify-content: center;
                
                row-gap: 10px;
                margin: 0;
                @media screen and (max-width:500px){
                    font-size: 1.2em;
                }
                span{
                    width: 150px;
                    height: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 20px;
                    flex-direction: column;
                    color: white;
                    row-gap: 8px;
                    @media screen and (max-width:500px){
                        height: 120px;
                        width: 180px;
                    }
                }
                svg{
                    color: white;
                    font-size: 2.5em;
                    @media screen and (max-width:500px){
                        font-size: 2em;
                    }
                }
            }
            p{
                color: white;
                text-align: center;
                font-size: 1.3em;
                width: 100%;
                max-width: 700px;
                margin: 0 auto;
                @media screen and (max-width:500px){
                    text-align: justify;
                }
            }
        }
        .admindashbored{
            width: 100%;
            display: flex;
            justify-content: space-between;
            column-gap: 20px;
            @media screen and (max-width:500px){
                flex-wrap: wrap;
                row-gap: 20px;
            }
            div{
                h1{
                    color: $main;
                    font-size: 3em;
                    padding:.3em .5em;
                    margin-top: 0;
                    background-color: $sub;
                    text-align: center;
                    border-radius: 50px;
                    @media screen and (max-width:500px){
                        font-size: 1.5em;
                    }
                }
                p{
                    font-size: 1.5em;
                    text-align: center;
                    @media screen and (max-width:500px){
                        font-size: 1.3em;
                        padding: 0 .5em;
                        margin-bottom: 0em;
                    }
                }
                
            }
            img{
                outline: none;
                border: none;
                width: 100%;
                max-width: 800px;
                border-radius: 20px;
                flex-grow: 1;
                cursor: pointer;
                border: 20px solid $main;
                @media screen and (max-width:500px){
                    border: 10px solid $main;
                }
            }
            ul{
                line-height: 30px;
                @media screen and (max-width:500px){
                    list-style-type: none;
                    padding: 0 .3em;
                    margin: 0;
                }
                li{
                    text-align: justify;
                    @media screen and (max-width:500px){
                        margin: 2em 1em;
                    }
                    &:nth-child(3){
                        margin-bottom: 1em;
                    }
                    strong{
                        background-color: $sub;
                        color: $main;
                        border-radius: 50px;
                        padding: 0 .5em;
                    }
                }
            }
        }
    }
    .AboutTheCreator{
        width: 100%;
        padding: 1em 2em;
        
        @media screen and (max-width:500px){
            padding: .5em .3em;
            padding-top: 0;
        }
        &>div{
            background: linear-gradient($main, rgb(3 10 22 / 75%)), url("https://images.pexels.com/photos/4614473/pexels-photo-4614473.jpeg");
            background-size: cover;
            background-position: 100% 50%;
            border-radius: 28px;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3em 2em 5em;
            @media screen and (max-width:500px){
                padding: 2em ;
                border-radius: 20px;
            }
            h1{
                color: white;
                font-size: 2.5em;
                margin-bottom: 20px;
                @media screen and (max-width:500px){
                    text-align: center;
                }
                span{
                   background-color: $sub;
                   color: $main;
                   border-radius: 50px;
                   padding: .2em 1em;
                   font-size: .7em;
                   cursor: pointer;
                }
            }
            p{
                color: white;
                font-size: 1.5em;
                text-align: center;
                max-width: 800px;
                @media screen and (max-width:500px){
                    font-size: 1em;
                    text-align: justify;
                    line-height: 2em;
                }
            }
            a{
                background-color: white;
                color: $main;
                text-decoration: none;
                padding: .6em 1em;
                width: 100%;
                max-width: 400px;
                text-align: center;
                border-radius: 50px;
                font-size: 1.2em;
                @media screen and (max-width:500px){
                    font-size: 1em;
                }
            }
        }
    }
    footer{
        width: 100%;
        padding: 1em 2em;
        padding-top: 0;
        padding-bottom: 0;
        @media screen and (max-width:500px){
            padding: 0em .3em;
            padding-bottom: 0;
        }
        div{
            background-color: $main;
            border-top-left-radius: 28px;
            border-top-right-radius: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: linear-gradient($main, rgb(3 10 22 / 75%)), url("https://images.pexels.com/photos/2268577/pexels-photo-2268577.jpeg");
            background-size: cover;
            background-position: 100% 50%;
            padding: 1em 3em;
            @media screen and (max-width:500px){
                flex-direction: column;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }
            img{
                height: 60px;
                @media screen and (max-width:500px){
                    height: 70px;
                }
            }
            label{
                color: white;
                padding-top: .6em;
                @media screen and (max-width:500px){
                    text-align: center;
                    margin-top: 1em;
                    padding-top: 0;
                }
            }
        }
    }
}