.AdminLogin{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $main;
    background: linear-gradient(rgb(6, 13, 26), rgba(0, 0, 0, 0.75)), url("https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg");
    background-size: cover;
    color: white;
    padding: 1em;
    svg{
        color: white;
        @media screen and (max-width:500px){
            display: none;
        }
    }
    h1{
        font-size: 3em;
        font-weight: 900;
        margin-top: 10px;color: $sub;
        display: flex;
        align-items: center;
        text-align: center;
        column-gap: 5px;
        @media screen and (max-width:500px){
            font-size: 2.3em;
        }
        svg{
            font-size: .8em;
        }
    }
    form{
        width: 100%;
        max-width: 1100px;
        display: flex;
        input{
            outline: none;
            font-size: 1.5em;
            padding: .8em 2em;
            flex-grow: 1;
            border: none;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            @media screen and (max-width:500px){
                font-size: 1em;
                padding: .8em;
                padding-right: 1.5em;
            }
        }
        button{
            outline: none;
            font-size: 1.5em;
            padding: .8em 2em;
            border: none;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            background-color: $sub;
            color: $main;
            cursor: pointer;
            @media screen and (max-width:500px){
                font-size: 1em;
                padding: .5em 1em;
            }
        }
    }
}