.AdminDash{
    width: 100%;
    position: relative;
    header{
        @media screen and (max-width:900px){
            font-size: .85em;
            padding: .8em 1em;
        }
    }
    .g2{
        width: 100%;
        display: flex;
        margin-top: 10px;
        column-gap: 10px;
        row-gap: 10px;
        @media screen and (max-width:900px){
            flex-direction: column;
        }
        .MasajedCount{
            flex-grow: .5;
            background:linear-gradient(rgb(6 13 26), rgb(0 0 0 / 50%)), url("https://images.pexels.com/photos/3624566/pexels-photo-3624566.jpeg");
            background-size: cover;
            background-position: 50%;
            min-width: 300px;
            border-radius: 10px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 4.5em;
            column-gap: 3px;
            font-weight: 900;
            @media screen and (max-width:900px){
                font-size: 2.8em;
                padding: 1em .5em;
                min-width: auto;
            }
            svg{
                padding-bottom: .1em;
            }
        }
        .addUser {
            width: 100%;
            border-radius: 10px;
            padding: 3em 2em;
            background-image: linear-gradient(19deg, #0D182BB0 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
            @media screen and (max-width:900px){
                padding: 2em 1em;
            }
            h2{
                margin: 0;
                color: white;
                text-align: center;
                font-size: 3.5em;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width:900px){
                    font-size: 1.5em;
                    svg{
                        display: none;
                    }
                }
            }
            form{
                width: 100%;
                display: flex;
                column-gap: 10px;
                row-gap: 10px;
                justify-content: space-around;flex-wrap: wrap;
                input{
                    flex-grow: 1;
                    outline: none;
                    border: none;
                    padding: .8em 1em;
                    font-size: 1em;
                    border-radius: 10px;
                    
                    min-width: 250px;
                    @media screen and (max-width:900px){
                        min-width: auto;
                        font-size: .9em;
                    }
                }
                button{
                    width: 100%;
                    outline: none;
                    border: none;
                    font-size: 1em;
                    background-color: $sub;
                    color: $main;
                    padding: .8em 1em;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    .masajedHolder{
        width: 100%;
        margin-top: 10px;
        background-image: linear-gradient(19deg, #0D182BB0 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
        border-radius: 10px;
        padding: 2em;
        position: relative;
        @media screen and (max-width:900px){
            padding: 1em;
        }
        h2{
            display: flex;
            justify-content: center;
            font-size: 4em;
            color: white;
            margin-bottom: 0;
            margin-top: 15px;
            @media screen and (max-width:900px){
                margin-top: 0;
                font-size: 3em;
            }
        }
        .search-container{
            width: 100%;
            display: flex;
            background-color: white;
            align-items: center;
            padding-left: 1em;
            border-radius: 10px;
            position: relative;
            svg{
                font-size: 1.8em;
                color: $main;
            }
            input{
                flex-grow: 1;
                outline: none;
                border: none;
                font-size: 1.5em;
                padding: .5em 1em;
                border-radius: 10px;
                @media screen and (max-width:900px){
                    font-size: 1em;
                    padding: .8em 1.5em;
                    svg{
                        font-size: 1em;
                    }
                }
            }
        }
        .innerMasajedHolder{
            width: 100%;
            
            ul{
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                column-gap: 10px;
                row-gap: 10px;
                list-style: none;
                padding: 0;
                li{
                    flex-grow: 1;
                    background-color: white;
                    padding: .8em 1em;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 10px;
                    width: 300px;
                    span{
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                        svg{
                            color: $main;
                        }
                    }
                    svg{
                        color: $main;
                    }
                }
            }
        }
    }
}
.delete-confirm-popup{
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #0d182bf1;
    p{
        font-size: 4em;
        color: white;
        font-weight: 900;
        margin-bottom: 20px;
    }
    div{
        display: flex;
        column-gap: 10px;
        button{
            outline: none;
            border: none;
            border-radius: 10px;
            padding: .8em 1em;
            font-size: 1.5em;
            width: 150px;
            cursor: pointer;
            &:nth-child(1){
                background-color: $sub;
            }
            &:nth-child(2){
                background-color: rgb(255, 177, 177);
            }
        }
    }
}