.ClientDash{
    padding: 1em;
    width: 100%;
    background-color: aliceblue;
    @media screen and (max-width:800px){
        padding: .4em;
    }
    header{
        width: 100%;
        background-color: $main;
        padding: 1em 2em;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-size: 100% 100%;
        background-position: 0px 0px,0px 0px;
        background-image: linear-gradient(19deg, #0D182BB0 0%, #0d182b 57%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
        @media screen and (max-width:800px){
            padding: .5em .8em;
        }
        label{
            color: white;
            font-size: 1.5em;
            @media screen and (max-width:800px){
                font-size: 1em;
            }
        }
        button{
            outline: none;
            border: none;
            background-color: white ;
            font-size: 1em;
            padding: .5em 2em;
            border-radius: 5px;
            display: flex;
            align-items: center;
            column-gap: 1px;
            color: $main;
            cursor: pointer;
        }
    }
    main{
        display: flex;
        column-gap: 10px;
        @media screen and (max-width:800px){
            flex-direction: column-reverse  ;
        }
    }
    .infoHolder{
        flex-grow: 1;
        margin-top: 10px;
        row-gap: 10px;
        column-gap: 10px;
        display: flex;
        flex-direction: column;
        
        .todayDate{
            width: 100%;
            height: 300px;
            border-radius: 10px;
            background:linear-gradient(rgb(6 13 26), rgb(0 0 0 / 50%)), url("https://images.pexels.com/photos/415574/pexels-photo-415574.jpeg");
            background-size: cover;
            background-position: 50%;
            text-align: center;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 3em;
            font-weight: 900;
            @media screen and (max-width:1100px){
                font-size: 2em;
            }
            @media screen and (max-width:800px){
                font-size: 1.5em;
                height: 150px;
            }
        }
        .booksHolder{
            width: 100%;
            background-size: 100% 100%;
            background-position: 0px 0px,0px 0px;
            background-image: linear-gradient(19deg, #0D182BB0 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
            padding: 2em;
            border-radius: 10px;
            box-sizing: border-box;
            @media screen and (max-width:800px){
                display: none;
            }
            &>h1{
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 2.5em;
                margin-bottom: 50px;
                @media screen and (max-width:1100px){
                    font-size: 2em;
                }
            }
            .books{
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                @media screen and (max-width:1100px){
                    font-size: .85em;
                }
                .book{
                    color: white;
                    background: rgba(13, 24, 43, 0.64);
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(7.9px);
                    -webkit-backdrop-filter: blur(7.9px);
                    border: 1px solid rgba(108, 122, 148, 0.3);
                    border-radius: 10px;
                    padding: 2.5em 1.5em;
                    h1{
                        font-size: 1.5em;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    p{
                        margin: 1em 0;
                        font-size: 1.1em;
                    }
                    a{
                        background-color: $sub;
                        color: $main;
                        text-decoration: none;
                        padding: .5em 1.5em;
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                        width: 150px;
                        border-radius: 10px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .form-container{
        width: 100%;
        max-width: 500px;
        min-width: 450px;
        height: fit-content;
        margin-top: 10px;
        background-size: 100% 100%;
        background-position: 0px 0px,0px 0px;
        background-image: linear-gradient(19deg, #0D182BB0 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
        border-radius: 10px;
        padding: 2em 2em;
        position: relative;
        @media screen and (max-width:800px){
            min-width: auto;
            padding: 1.5em;
        }
        form{
            width: 100%;
            position: relative;
            .sendBtn{
                width: 100%;
                outline: none;
                background-color: $sub;
                border: none;
                border-radius: 10px;
                padding: .5em;
                font-size: 1.3em;
                cursor: pointer;
            }
            .innerFormHolder{
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .fieldGroup{
                    border-top: 1px solid white;
                    padding-top:1em ;
                    margin-top: 1em;
                    &>div{
                        display: flex;
                        
                        column-gap: 10px;
                        .field{
                            width: 50%;
                            flex-grow: 1;
                            min-width: 50px;
                        }
                    }
                }
                .RamadanSection{
                    position: relative;
                    & > img {
                        position: relative;
                        width: 100%;
                        
                    }
                    & > div {
                        display: block;
                        .field{
                            width: 100%;
                            max-width: auto;
                            & > div > button > svg {
                                color: white;
                            }   
                            & > div > input {
                                flex-grow: 1;
                            }
                        }
                    }
                }
                .rightSideForm{
                    flex-grow: 1;
                    .field{
                        display: flex;
                        flex-direction: column;
                        color: white;
                        margin-bottom: 10px;
                        label{
                            font-size: 1.5em;
                            margin-bottom: 3px;
                            padding-right:0px;
                            display: flex;
                            align-items: center;
                            column-gap: 5px;
                            svg{
                                color: $sub;
                            }
                            @media screen and (max-width:800px){
                                font-size: 1.2em;
                            }
                        }
                        input , select {
                            outline: none;
                            border: none;
                            font-size: 1.1em;
                            padding: .8em 1em;
                            border-radius: 10px;
                            max-width: 700px;
                            @media screen and (max-width:800px){
                                font-size: 1em;
                            }
                        }
                        select{
                            appearance: none;
                        }
                    }
                    
                }
                .leftSideForm{
                    border-top: 0.5px solid white;
                    padding-top: 1em;
                    margin-top: 1em;
                    .field{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-bottom: 1em;
                        audio::-webkit-media-controls-enclosure {
                            border-radius: 10px;
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;

                        }
                        audio{
                            width: 100%;
                        }
                        input{
                            width: 100%;
                        }
                        label{
                            color: white;
                            font-size: 1.5em;
                            margin-bottom: 5px;
                            display: flex;
                            align-items: center;
                            column-gap: 5px;
                            svg{
                                color: $sub;
                            }
                        }
                        img{
                            width: 100%;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }
                        button{
                            border-radius: 10px;
                            outline: none;
                            border: none;
                        }
                        .chnageImageBtn{
                            border-top-left-radius: 0px;
                            border-top-right-radius: 0px;
                            font-size: 1em;
                            background-color: rgb(255, 86, 86);
                            padding: .5em 0;
                            cursor: pointer;
                        }
                        .changeAdhenBtn{
                            font-size: 1em;
                            background-color: rgb(255, 86, 86);
                            padding: .5em 0;
                            cursor: pointer;
                            margin-top: 0em;
                            border-top-left-radius: 0px;
                            border-top-right-radius: 0px;
                        }
                    }
                }
                
            }
        }
    }
}

