@import url('https://fonts.googleapis.com/css2?family=Fustat:wght@200..800&display=swap');

*{
    font-family: "Fustat", sans-serif;
    box-sizing: border-box;
    direction: rtl;
}

body{
    margin: 0;
    background-color: aliceblue;
}

$main:#0d182b;
$sub:#c1ff72;


@import "Home";
@import "ClientLogin";
@import "ClientDash";
@import "AdminLogin";
@import "AdminDash";