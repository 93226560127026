@import url("https://fonts.googleapis.com/css2?family=Fustat:wght@200..800&display=swap");
* {
  font-family: "Fustat", sans-serif;
  box-sizing: border-box;
  direction: rtl;
}

body {
  scrollbar-width: 0px;
  margin: 0;
  background-color: #0d182b !important;
  height: 100vh;
}
body::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 500px) {
  body {
    flex-direction: column;
    padding: 0;
    overflow-y: scroll;
    justify-content: space-between;
    height: auto;
    scrollbar-width: 0px;
    position: relative;
    min-height: 100vh;
  }
}

#root {
  height: 100vh;
}
@media screen and (max-width: 500px) {
  #root {
    height: auto;
    min-height: 100vh;
  }
}
#root > div {
  height: 100vh;
}
@media screen and (max-width: 500px) {
  #root > div {
    height: auto;
    min-height: 100vh;
  }
}

.clock {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.clock .backgroundImage {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  filter: brightness(0.65);
  -o-object-fit: cover !important;
     object-fit: cover !important;
}
@media screen and (max-width: 500px) {
  .clock .backgroundImage {
    -o-object-fit: initial !important;
       object-fit: initial !important;
  }
}
.clock nav {
  display: flex;
  justify-content: space-between;
}
.clock nav svg {
  color: white;
  font-size: 3em;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .clock nav svg {
    font-size: 2.5em;
  }
}
.clock nav img {
  width: 80px;
}
@media screen and (max-width: 500px) {
  .clock nav img {
    width: 70px;
  }
}
.clock .Menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0d182b;
  width: 100%;
  height: 100vh;
  z-index: 999;
}
.clock .Menu .insideMenuHolder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10em;
  row-gap: 10px;
}
.clock .Menu .insideMenuHolder .activateSound {
  font-size: 3em;
  background-color: white;
  color: #0d182b;
  outline: none;
  text-decoration: none;
  border: none;
  display: flex;
  flex-direction: row-reverse;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding: 0.3em 1em;
  border-radius: 50px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .clock .Menu .insideMenuHolder .activateSound {
    font-size: 1.8em;
  }
}
.clock .Menu .insideMenuHolder .downloadBtn {
  font-size: 3em;
  background-color: #c1ff72;
  color: #0d182b;
  outline: none;
  text-decoration: none;
  border: none;
  display: flex;
  flex-direction: row-reverse;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding: 0.3em 1em;
  border-radius: 50px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .clock .Menu .insideMenuHolder .downloadBtn {
    font-size: 1.8em;
  }
}
.clock main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
}
.clock main .masjidName {
  color: white;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  margin: 0;
  font-weight: 900;
  font-size: 2em;
  margin-bottom: 15px;
}
@media screen and (max-width: 500px) {
  .clock main .masjidName {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0.5em;
  }
}
.clock main .hijriDate, .clock main .gregorianDate {
  color: white;
  font-size: 1.5em;
  margin: 0;
  font-weight: 900;
  margin-bottom: 10px;
}
@media screen and (max-width: 500px) {
  .clock main .hijriDate, .clock main .gregorianDate {
    font-size: 1.1em;
    text-align: center;
    font-weight: 500;
  }
}
.clock main .NextAdhen {
  color: white;
  font-size: 1.5em;
  margin: 0;
  font-weight: 900;
  margin-bottom: 10px;
}
@media screen and (max-width: 500px) {
  .clock main .NextAdhen {
    font-size: 1.1em;
    text-align: center;
    font-weight: 500;
  }
}
.clock main .currentTime {
  color: white;
  font-size: 4em;
  margin: 0;
  font-weight: 900;
  direction: ltr;
}
@media screen and (max-width: 500px) {
  .clock main .currentTime {
    text-align: center;
    font-weight: 500;
  }
}
.clock main .ads {
  color: white;
  margin-bottom: 0;
}
@media screen and (max-width: 500px) {
  .clock main .ads {
    text-align: center;
    font-weight: 500;
    font-size: 1em;
  }
}
.clock main .hadeeth {
  color: #c1ff72;
  margin-top: 0;
}
@media screen and (max-width: 500px) {
  .clock main .hadeeth {
    text-align: center;
    font-weight: 500;
    font-size: 0.8em;
  }
}
.clock main .audioHolder {
  position: absolute;
  z-index: -1;
  top: -100%;
}
.clock main table {
  color: white;
  font-size: 6vh;
  border-spacing: 15px;
  font-weight: 900;
}
.clock main .centerHolder {
  flex-grow: 0.35;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  .clock main .centerHolder {
    flex-grow: 0.2;
  }
}
.clock main tr {
  text-align: center;
  margin: 10px 0;
  font-weight: 900;
}
.clock main th {
  font-weight: 900;
}
.clock main .bottomHolder {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 1em;
  font-size: 1.2em;
}
@media screen and (max-width: 500px) {
  .clock main .bottomHolder {
    padding: 0 1.7em 0 1.7em;
    font-size: 1em;
    row-gap: 5px;
    margin-top: 0.5em;
  }
}
.clock main .bottomHolder h2, .clock main .bottomHolder h3 {
  margin: 0;
  max-height: 800px;
}

.duaScreen {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
}
@media screen and (max-width: 500px) {
  .duaScreen {
    background-image: url(../assets/duaMobile.jpg);
  }
}

@media screen and (max-width: 1100px) {
  .prayer-table-h {
    font-size: 5vh !important;
  }
}
@media screen and (max-width: 920px) {
  .prayer-table-h {
    font-size: 4.5vh !important;
  }
}
@media screen and (max-width: 500px) {
  .prayer-table-h {
    font-size: 3.5vh !important;
  }
}
@media screen and (max-width: 350px) {
  .prayer-table-h {
    font-size: 3vh !important;
  }
}
@media screen and (max-width: 270px) {
  .prayer-table-h {
    font-size: 1.1em !important;
  }
}

@media screen and (max-width: 1100px) {
  .prayer-table-v {
    font-size: 5vh !important;
  }
}
@media screen and (max-width: 920px) {
  .prayer-table-v {
    font-size: 4.5vh !important;
  }
}
@media screen and (max-width: 500px) {
  .prayer-table-v {
    font-size: 3.5vh !important;
  }
}
@media screen and (max-width: 350px) {
  .prayer-table-v {
    font-size: 3vh !important;
  }
}
@media screen and (max-width: 270px) {
  .prayer-table-v {
    font-size: 1.1em !important;
  }
}

.wamidh {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: wamidh 1.5s infinite;
  display: block;
}

@keyframes wamidh {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.nextSalatActiveH {
  padding: 0px 0.35em;
  background-color: white;
  color: #06402b;
  border-radius: 28px;
  padding: 0.1em 0.9em;
}

.nextSalatActiveV {
  padding: 0px 0.35em;
  background-color: white;
  color: #06402b;
  border-radius: 28px;
  padding: 0.5em 0.1em;
}

.salettd {
  padding: 0px 0.35em;
}

@media screen and (max-width: 1200px) {
  .salettdV {
    padding: 0px 0.5%;
  }
}
@media screen and (min-width: 1300px) {
  .salettdV {
    padding: 0px 3%;
  }
}

.blackScreen {
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  color: white;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  background-color: black;
  align-items: center;
  font-size: 10em;
  padding: 1em;
  text-align: center;
}

th.remainingTimeForIkama {
  background-color: #c1ff72;
  color: #06402b;
  padding: 5px 36px;
  border-radius: 11px;
  font-size: 1.2em;
  max-width: 160px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  th.remainingTimeForIkama {
    padding: 5px;
    font-size: 1em;
  }
}/*# sourceMappingURL=clock.css.map */