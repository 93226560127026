@import url('https://fonts.googleapis.com/css2?family=Fustat:wght@200..800&display=swap');
$main:#0d182b;
$sub:#c1ff72;
*{
    font-family: "Fustat", sans-serif;
    box-sizing: border-box;
    direction: rtl;
}

body{
    &::-webkit-scrollbar{
        display: none;
    }
    scrollbar-width: 0px;
    margin: 0;
    background-color: $main !important;
    height: 100vh;
    @media screen and (max-width:500px){
        flex-direction: column;
        padding: 0;
        overflow-y: scroll;
        justify-content: space-between;
        height: auto;
        scrollbar-width: 0px;
        position: relative;
        min-height: 100vh;
    }
}

#root{
    height: 100vh;
    @media screen and (max-width:500px){
        height: auto;
        min-height: 100vh;
    }
    &>div{
        height: 100vh;
        @media screen and (max-width:500px){
            height: auto;
            min-height: 100vh;
        }
    }
}




.clock{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .backgroundImage{
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: 0;
        filter: brightness(.65);
        object-fit: cover !important;
        @media screen and (max-width:500px){
            object-fit: initial !important;
        }
    }
    nav{
        display: flex;
        justify-content: space-between;
        svg{
            color: white;
            font-size: 3em;
            cursor: pointer;
            @media screen and (max-width:500px){
                font-size: 2.5em;
            }
        }
        img{
            width: 80px;
            @media screen and (max-width:500px){
                width: 70px;
            }
        }
    }
    .Menu{
        position: fixed;
        top: 0;
        left: 0;
        background-color: $main;
        width: 100%;
        height: 100vh;
        z-index: 999;
        .insideMenuHolder{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-bottom: 10em;
            row-gap: 10px;
            
            .activateSound{
                font-size: 3em;
                background-color: white;
                color: $main;
                outline: none;
                text-decoration: none;
                border: none;
                display: flex;
                flex-direction: row-reverse;
                column-gap: 10px;
                align-items: center;
                padding: .3em 1em;
                border-radius: 50px;
                cursor: pointer;
                @media screen and (max-width:500px){
                    font-size: 1.8em;
                }
            }
            .downloadBtn{
                font-size: 3em;
                background-color: #c1ff72;
                color: $main;
                outline: none;
                text-decoration: none;
                border: none;
                display: flex;
                flex-direction: row-reverse;
                column-gap: 10px;
                align-items: center;
                padding: .3em 1em;
                border-radius: 50px;
                cursor: pointer;
                @media screen and (max-width:500px){
                    font-size: 1.8em;
                }
            }
        }
    }
    main{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex-grow: 1;
        .masjidName{
            color: white;
            display: flex;
            align-items: center;
            column-gap: 5px;
            margin: 0;
            font-weight: 900;
            font-size: 2em;
            margin-bottom: 15px;
            @media screen and (max-width:500px){
                font-size: 1.5em;
                font-weight: 500;
                margin-top: .5em;
            }
        }
        .hijriDate , .gregorianDate{
            color: white;
            font-size: 1.5em;
            margin: 0;
            font-weight: 900;
            margin-bottom: 10px;
            @media screen and (max-width:500px){
                font-size: 1.1em;
                text-align: center;
                font-weight: 500;
            }
        }
        .NextAdhen{
            color: white;
            font-size: 1.5em;
            margin: 0;
            font-weight: 900;
            margin-bottom: 10px;
            @media screen and (max-width:500px){
                font-size: 1.1em;
                text-align: center;
                font-weight: 500;
            }
        }
        .currentTime{
            color: white;
            font-size: 4em;
            margin: 0;
            font-weight: 900;
            direction: ltr;
            @media screen and (max-width:500px){
                text-align: center;
                font-weight: 500;
            }
        }
        .ads{
            color: white;
            margin-bottom: 0;
            @media screen and (max-width:500px){
                text-align: center;
                font-weight: 500;
                font-size: 1em;
            }
        }
        .hadeeth{
            color: $sub;
            margin-top: 0;
            @media screen and (max-width:500px){
                text-align: center;
                font-weight: 500;
                font-size: .8em;
            }
        }
        .audioHolder{
            position: absolute;
            z-index: -1;
            top: -100%;
        }
        table{
            color: white;
            font-size: 6vh;
            border-spacing: 15px;
            font-weight: 900;
        }
        .centerHolder{
            flex-grow: .35;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width:500px){
                flex-grow: .2;
            }
        }
        tr{
            text-align: center;
            margin: 10px 0;
            font-weight: 900;
        }
        th{
            font-weight: 900;
        }
        .bottomHolder{
            text-align: center;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            padding: 1em;
            font-size: 1.2em;
            @media screen and (max-width:500px){
                padding: 0 1.7em 0 1.7em;
                font-size: 1em;
                row-gap: 5px;
                margin-top: .5em;
            }
            h2 , h3 {
                margin: 0;
                max-height: 800px;
            }
        }
        
    }
}

.duaScreen{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (max-width:500px){
        background-image: url(../assets/duaMobile.jpg);
    }
}
.prayer-table-h{
    @media screen and (max-width:1100px){
        font-size: 5vh !important;
    }
    @media screen and (max-width:920px){
        font-size: 4.5vh !important;
    }
    @media screen and (max-width:500px){
        font-size: 3.5vh !important;
    }
    @media screen and (max-width:350px){
        font-size: 3vh !important;
    }
    @media screen and (max-width:270px){
        font-size: 1.1em !important;
    }
}

.prayer-table-v{
    @media screen and (max-width:1100px){
        font-size: 5vh !important;
    }
    @media screen and (max-width:920px){
        font-size: 4.5vh !important;
    }
    @media screen and (max-width:500px){
        font-size: 3.5vh !important;
    }
    @media screen and (max-width:350px){
        font-size: 3vh !important;
    }
    @media screen and (max-width:270px){
        font-size: 1.1em !important;
    }
}

.wamidh{
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    animation: wamidh 1.5s infinite;
    display: block;
}

@keyframes wamidh {
    0%{opacity: 0;}
    50%{opacity: .7;}
    100%{opacity: 0;}
}

.nextSalatActiveH {
    padding: 0px 0.35em;
    background-color: white;
    color: #06402b;
    border-radius: 28px;
    padding: .1em .9em;
}

.nextSalatActiveV {
    padding: 0px 0.35em;
    background-color: white;
    color: #06402b;
    border-radius: 28px;
    padding:  .5em .1em;
}
.salettd{
    
    padding: 0px 0.35em;
}
.salettdV{
    
    @media screen and (max-width:1200px){
        padding: 0px calc(100% * .5 / 100);
    }
    @media screen and (min-width:1300px){
        padding: 0px calc(100% * 3 / 100);
    }
}
.blackScreen {
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    color: white;
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: center;
    background-color: black;
    align-items: center;
    font-size: 10em;
    padding: 1em;
    text-align: center;
}
th.remainingTimeForIkama {
    background-color: #c1ff72;
    color: #06402b;
    padding: 5px 36px;
    border-radius: 11px;
    font-size: 1.2em;
    max-width: 160px;
    display: flex;
    justify-content: center;
    @media screen and (max-width:500px){
        padding: 5px;
        font-size: 1em;
    }
}