@import url("https://fonts.googleapis.com/css2?family=Fustat:wght@200..800&display=swap");
* {
  font-family: "Fustat", sans-serif;
  box-sizing: border-box;
  direction: rtl;
}

body {
  margin: 0;
  background-color: aliceblue;
}

.Home {
  width: 100%;
  position: relative;
  background-color: aliceblue !important;
}
.Home nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1em 2.5em 1em 2em;
}
@media screen and (max-width: 500px) {
  .Home nav {
    padding: 1em 0.8em;
  }
}
.Home nav img {
  width: 100px;
}
@media screen and (max-width: 500px) {
  .Home nav img {
    width: 70px;
  }
}
.Home nav div {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
@media screen and (max-width: 500px) {
  .Home nav div {
    -moz-column-gap: 5px;
         column-gap: 5px;
  }
}
.Home nav div a {
  background-color: white;
  text-decoration: none;
  padding: 0.5em 2.8em;
  color: #0d182b;
  border-radius: 50px;
  font-size: 1.05em;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .Home nav div a {
    font-size: 0.9em;
    padding: 0.5em 2em;
  }
}
.Home nav div a:nth-child(1) {
  background-color: #c1ff72;
}
.Home .Hero {
  background: linear-gradient(rgb(6, 13, 26), rgba(0, 0, 0, 0.75)), url("https://images.pexels.com/photos/16423534/pexels-photo-16423534/free-photo-of-men-praying-at-the-ali-kuscu-mosque-at-istanbul-airport.jpeg");
  position: relative;
  height: 100vh;
  top: 0;
  width: 100%;
  background-size: cover;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Home .Hero h1 {
  color: #c1ff72;
  font-size: 4em;
  margin-bottom: 0;
}
@media screen and (max-width: 500px) {
  .Home .Hero h1 {
    padding-right: 0.5em;
    font-size: 3em;
  }
}
.Home .Hero h2 {
  color: white;
}
@media screen and (max-width: 500px) {
  .Home .Hero h2 {
    padding-right: 1.5em;
    font-size: 1.2em;
  }
}
.Home .Hero a {
  width: 100%;
  max-width: 260px;
  background-color: #c1ff72;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.8em 1em;
  border-radius: 50px;
  display: flex;
  text-decoration: none;
  -moz-column-gap: 5px;
       column-gap: 5px;
  color: #0d182b;
  font-size: 1.1em;
}
.Home .Hero a svg {
  font-size: 1.3em;
}
.Home .Masajed {
  width: 100%;
  padding: 1em 2em;
  position: relative;
  overflow-x: hidden;
}
@media screen and (max-width: 500px) {
  .Home .Masajed {
    padding: 1em;
  }
}
.Home .Masajed > div {
  display: flex;
  flex-direction: column;
}
.Home .Masajed > div > h1 {
  font-size: 4.5em;
  margin: 0;
  margin-top: 35px;
  color: #0d182b;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .Home .Masajed > div > h1 {
    font-size: 2em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.Home .Masajed > div .Search {
  display: flex;
  font-size: 3em;
  background-color: #0d182b;
  border-radius: 50px;
  padding: 0.3em 0.5em;
  margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .Home .Masajed > div .Search {
    font-size: 2em;
    margin-bottom: 10px;
    padding: 0.4em 0.8em 0.4em 0.6em;
  }
}
.Home .Masajed > div .Search input {
  flex-grow: 1;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 0.5em;
  color: white;
}
.Home .Masajed > div .Search svg {
  font-size: 0.8em;
  color: #c1ff72;
}
.Home .Masajed > div .masajedHolder {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  row-gap: 10px;
  -moz-column-gap: 10px;
       column-gap: 10px;
  font-size: 0.9em;
}
.Home .Masajed > div .masajedHolder a {
  text-decoration: none;
  width: 300px;
  background-color: #0d182b;
  border-radius: 50px;
  padding: 0.4em 1.7em;
  position: relative;
  flex-grow: 1;
}
.Home .Masajed > div .masajedHolder a div {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  color: white;
}
@media screen and (max-width: 500px) {
  .Home .Masajed > div .masajedHolder a div h3 {
    margin: 0;
  }
}
.Home .Masajed > div .masajedHolder a div svg {
  color: #c1ff72;
}
@media screen and (max-width: 500px) {
  .Home .Masajed > div .masajedHolder a div svg {
    width: 30px;
  }
}
.Home .Masajed > div .masajedHolder a div::before {
  content: "";
  display: block;
  left: 1em;
  width: 15px;
  height: 15px;
  z-index: 9;
  background-color: #c1ff72;
  position: absolute;
  border-radius: 50px;
}
.Home .Masajed > div .masajedHolder a div:after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: transparent;
  position: absolute;
  left: 1em;
  border-radius: 50px;
  border: 5px solid #c1ff72;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Home .Masajed > div .masajedHolder p {
  font-size: 1.5em;
  color: #0d182b;
}
.Home .Masajed > div > span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 1.5em;
  font-size: 1.3em;
  color: #0d182b;
}
.Home .AboutTheClock {
  width: 100%;
  padding: 1em 2em;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheClock {
    padding: 0.5em 0.3em;
  }
}
.Home .AboutTheClock div {
  background: linear-gradient(#0d182b, rgba(3, 10, 22, 0.75)), url("https://images.pexels.com/photos/3624566/pexels-photo-3624566.jpeg");
  background-size: cover;
  background-position: 100% 45%;
  border-radius: 28px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 2em 5em;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheClock div {
    padding: 3em 2em 3.6em;
    border-radius: 20px;
  }
}
.Home .AboutTheClock div img {
  width: 200px;
}
.Home .AboutTheClock div p {
  color: white;
  font-size: 1.8em;
  text-align: center;
  max-width: 1000px;
  line-height: 2em;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheClock div p {
    font-size: 1em;
    text-align: justify;
  }
}
.Home .AboutTheClock div span {
  display: flex;
  width: 100%;
  max-width: 1000px;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheClock div span {
    -moz-column-gap: 10px;
         column-gap: 10px;
  }
}
.Home .AboutTheClock div span a {
  flex-grow: 1;
  text-decoration: none;
  background-color: white;
  text-align: center;
  padding: 0.9em;
  border-radius: 50px;
  color: #0d182b;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheClock div span a {
    padding: 0.5em;
  }
}
.Home .AboutTheClock div span a:nth-child(1) {
  background-color: #c1ff72;
}
.Home .AboutForSEO {
  width: 100%;
  padding: 1em 2em;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO {
    padding: 0.5em 0.3em;
    row-gap: 20px;
  }
}
.Home .AboutForSEO .responsiveDesign {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .responsiveDesign {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
.Home .AboutForSEO .responsiveDesign div h1 {
  color: #0d182b;
  font-size: 3em;
  padding: 0.3em 0.5em;
  margin-top: 0;
  background-color: #c1ff72;
  text-align: center;
  border-radius: 50px;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .responsiveDesign div h1 {
    font-size: 1.5em;
  }
}
.Home .AboutForSEO .responsiveDesign div p {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 2.3em;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .responsiveDesign div p {
    font-size: 1.3em;
    padding: 0 1em;
    margin-bottom: 1em;
  }
}
.Home .AboutForSEO .responsiveDesign div a {
  background-color: #0d182b;
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
  color: white;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
  padding: 0.5em;
  font-size: 1.5em;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .responsiveDesign div a {
    font-size: 1.1em;
  }
}
.Home .AboutForSEO .responsiveDesign img {
  outline: none;
  border: none;
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
  flex-grow: 1;
  cursor: pointer;
  border: 20px solid #0d182b;
}
.Home .AboutForSEO .optimized {
  width: 100%;
  padding: 3em 2em;
  background-color: #0d182b;
  border-radius: 20px;
  background: linear-gradient(#0d182b, rgba(3, 10, 22, 0.75)), url("https://images.unsplash.com/photo-1490530994484-73efcf105faf");
  background-size: cover;
  background-position: 100% 45%;
  border-radius: 28px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .optimized {
    padding: 3em 1.5em;
  }
}
.Home .AboutForSEO .optimized h1 {
  text-align: center;
  font-size: 3em;
  color: white;
  margin: 0;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .optimized h1 {
    font-size: 1.8em;
    margin-bottom: 0.2em;
  }
}
.Home .AboutForSEO .optimized h2 {
  text-align: center;
  display: flex;
  justify-content: center;
  row-gap: 10px;
  margin: 0;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .optimized h2 {
    font-size: 1.2em;
  }
}
.Home .AboutForSEO .optimized h2 span {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  color: white;
  row-gap: 8px;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .optimized h2 span {
    height: 120px;
    width: 180px;
  }
}
.Home .AboutForSEO .optimized h2 svg {
  color: white;
  font-size: 2.5em;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .optimized h2 svg {
    font-size: 2em;
  }
}
.Home .AboutForSEO .optimized p {
  color: white;
  text-align: center;
  font-size: 1.3em;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .optimized p {
    text-align: justify;
  }
}
.Home .AboutForSEO .admindashbored {
  width: 100%;
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .admindashbored {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
.Home .AboutForSEO .admindashbored div h1 {
  color: #0d182b;
  font-size: 3em;
  padding: 0.3em 0.5em;
  margin-top: 0;
  background-color: #c1ff72;
  text-align: center;
  border-radius: 50px;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .admindashbored div h1 {
    font-size: 1.5em;
  }
}
.Home .AboutForSEO .admindashbored div p {
  font-size: 1.5em;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .admindashbored div p {
    font-size: 1.3em;
    padding: 0 0.5em;
    margin-bottom: 0em;
  }
}
.Home .AboutForSEO .admindashbored img {
  outline: none;
  border: none;
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
  flex-grow: 1;
  cursor: pointer;
  border: 20px solid #0d182b;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .admindashbored img {
    border: 10px solid #0d182b;
  }
}
.Home .AboutForSEO .admindashbored ul {
  line-height: 30px;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .admindashbored ul {
    list-style-type: none;
    padding: 0 0.3em;
    margin: 0;
  }
}
.Home .AboutForSEO .admindashbored ul li {
  text-align: justify;
}
@media screen and (max-width: 500px) {
  .Home .AboutForSEO .admindashbored ul li {
    margin: 2em 1em;
  }
}
.Home .AboutForSEO .admindashbored ul li:nth-child(3) {
  margin-bottom: 1em;
}
.Home .AboutForSEO .admindashbored ul li strong {
  background-color: #c1ff72;
  color: #0d182b;
  border-radius: 50px;
  padding: 0 0.5em;
}
.Home .AboutTheCreator {
  width: 100%;
  padding: 1em 2em;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheCreator {
    padding: 0.5em 0.3em;
    padding-top: 0;
  }
}
.Home .AboutTheCreator > div {
  background: linear-gradient(#0d182b, rgba(3, 10, 22, 0.75)), url("https://images.pexels.com/photos/4614473/pexels-photo-4614473.jpeg");
  background-size: cover;
  background-position: 100% 50%;
  border-radius: 28px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 2em 5em;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheCreator > div {
    padding: 2em;
    border-radius: 20px;
  }
}
.Home .AboutTheCreator > div h1 {
  color: white;
  font-size: 2.5em;
  margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheCreator > div h1 {
    text-align: center;
  }
}
.Home .AboutTheCreator > div h1 span {
  background-color: #c1ff72;
  color: #0d182b;
  border-radius: 50px;
  padding: 0.2em 1em;
  font-size: 0.7em;
  cursor: pointer;
}
.Home .AboutTheCreator > div p {
  color: white;
  font-size: 1.5em;
  text-align: center;
  max-width: 800px;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheCreator > div p {
    font-size: 1em;
    text-align: justify;
    line-height: 2em;
  }
}
.Home .AboutTheCreator > div a {
  background-color: white;
  color: #0d182b;
  text-decoration: none;
  padding: 0.6em 1em;
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-radius: 50px;
  font-size: 1.2em;
}
@media screen and (max-width: 500px) {
  .Home .AboutTheCreator > div a {
    font-size: 1em;
  }
}
.Home footer {
  width: 100%;
  padding: 1em 2em;
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 500px) {
  .Home footer {
    padding: 0em 0.3em;
    padding-bottom: 0;
  }
}
.Home footer div {
  background-color: #0d182b;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(#0d182b, rgba(3, 10, 22, 0.75)), url("https://images.pexels.com/photos/2268577/pexels-photo-2268577.jpeg");
  background-size: cover;
  background-position: 100% 50%;
  padding: 1em 3em;
}
@media screen and (max-width: 500px) {
  .Home footer div {
    flex-direction: column;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.Home footer div img {
  height: 60px;
}
@media screen and (max-width: 500px) {
  .Home footer div img {
    height: 70px;
  }
}
.Home footer div label {
  color: white;
  padding-top: 0.6em;
}
@media screen and (max-width: 500px) {
  .Home footer div label {
    text-align: center;
    margin-top: 1em;
    padding-top: 0;
  }
}

.ClientLogin {
  width: 100%;
  height: 100vh;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 1em;
  background: linear-gradient(rgb(6, 13, 26), rgba(0, 0, 0, 0.75)), url("https://images.pexels.com/photos/908055/pexels-photo-908055.jpeg");
  background-size: cover;
}
.ClientLogin section, .ClientLogin .rightClientLogin {
  flex-grow: 1;
}
.ClientLogin section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4em;
  position: relative;
}
.ClientLogin section .backBtn {
  position: absolute;
  top: 1em;
  left: 1em;
  background-color: aliceblue;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;
  padding: 0.5em 2em;
  color: #0d182b;
  border-radius: 15px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}
.ClientLogin section .backBtn:hover {
  background-color: #c1ff72;
}
.ClientLogin section .backBtn svg {
  font-size: 1.7em;
}
@media screen and (max-width: 500px) {
  .ClientLogin section .backBtn {
    left: 0em;
    top: 0em;
  }
}
.ClientLogin section img {
  width: 210px;
}
@media screen and (max-width: 500px) {
  .ClientLogin section img {
    width: 150px;
    margin-top: 2.5em;
  }
}
.ClientLogin section h2 {
  font-size: 2.3em;
  color: white;
  margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .ClientLogin section h2 {
    font-size: 1.5em;
    text-align: center;
  }
}
.ClientLogin section form {
  width: 100%;
  max-width: 500px;
}
.ClientLogin section form button {
  width: 100%;
  padding: 0.8em;
  border-radius: 15px;
  outline: none;
  border: none;
  background-color: white;
  font-size: 1em;
  color: #0d182b;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}
.ClientLogin section form button:hover {
  background-color: #c1ff72;
  color: #0d182b;
}
@media screen and (max-width: 500px) {
  .ClientLogin section form button {
    font-size: 1em;
  }
}
.ClientLogin section form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.ClientLogin section form div label {
  font-size: 1.5em;
  color: white;
  margin-bottom: 5px;
}
@media screen and (max-width: 500px) {
  .ClientLogin section form div label {
    font-size: 1em;
  }
}
.ClientLogin section form div input {
  outline: none;
  padding: 0.8em 1em;
  border-radius: 15px;
  border: 1px solid #0d182b;
  font-size: 1em;
}
.ClientLogin .rightClientLogin {
  background: linear-gradient(rgb(20, 43, 85), rgba(0, 0, 0, 0.38)), url("https://images.pexels.com/photos/2349168/pexels-photo-2349168.jpeg");
  background-size: cover;
  border-radius: 20px;
  min-width: 40%;
  max-width: 600px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  justify-content: left;
  align-items: start;
  padding: 1em;
}
@media screen and (max-width: 500px) {
  .ClientLogin .rightClientLogin {
    display: none;
  }
}
.ClientLogin .rightClientLogin a {
  background-color: aliceblue;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  padding: 0.5em 2em;
  color: #0d182b;
  border-radius: 15px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}
.ClientLogin .rightClientLogin a:hover {
  background-color: #c1ff72;
}
.ClientLogin .rightClientLogin a svg {
  font-size: 1.7em;
}

.ClientDash {
  padding: 1em;
  width: 100%;
  background-color: aliceblue;
}
@media screen and (max-width: 800px) {
  .ClientDash {
    padding: 0.4em;
  }
}
.ClientDash header {
  width: 100%;
  background-color: #0d182b;
  padding: 1em 2em;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-image: linear-gradient(19deg, rgba(13, 24, 43, 0.6901960784) 0%, #0d182b 57%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
}
@media screen and (max-width: 800px) {
  .ClientDash header {
    padding: 0.5em 0.8em;
  }
}
.ClientDash header label {
  color: white;
  font-size: 1.5em;
}
@media screen and (max-width: 800px) {
  .ClientDash header label {
    font-size: 1em;
  }
}
.ClientDash header button {
  outline: none;
  border: none;
  background-color: white;
  font-size: 1em;
  padding: 0.5em 2em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  -moz-column-gap: 1px;
       column-gap: 1px;
  color: #0d182b;
  cursor: pointer;
}
.ClientDash main {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
@media screen and (max-width: 800px) {
  .ClientDash main {
    flex-direction: column-reverse;
  }
}
.ClientDash .infoHolder {
  flex-grow: 1;
  margin-top: 10px;
  row-gap: 10px;
  -moz-column-gap: 10px;
       column-gap: 10px;
  display: flex;
  flex-direction: column;
}
.ClientDash .infoHolder .todayDate {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  background: linear-gradient(rgb(6, 13, 26), rgba(0, 0, 0, 0.5)), url("https://images.pexels.com/photos/415574/pexels-photo-415574.jpeg");
  background-size: cover;
  background-position: 50%;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: 900;
}
@media screen and (max-width: 1100px) {
  .ClientDash .infoHolder .todayDate {
    font-size: 2em;
  }
}
@media screen and (max-width: 800px) {
  .ClientDash .infoHolder .todayDate {
    font-size: 1.5em;
    height: 150px;
  }
}
.ClientDash .infoHolder .booksHolder {
  width: 100%;
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-image: linear-gradient(19deg, rgba(13, 24, 43, 0.6901960784) 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
  padding: 2em;
  border-radius: 10px;
  box-sizing: border-box;
}
@media screen and (max-width: 800px) {
  .ClientDash .infoHolder .booksHolder {
    display: none;
  }
}
.ClientDash .infoHolder .booksHolder > h1 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.5em;
  margin-bottom: 50px;
}
@media screen and (max-width: 1100px) {
  .ClientDash .infoHolder .booksHolder > h1 {
    font-size: 2em;
  }
}
.ClientDash .infoHolder .booksHolder .books {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
@media screen and (max-width: 1100px) {
  .ClientDash .infoHolder .booksHolder .books {
    font-size: 0.85em;
  }
}
.ClientDash .infoHolder .booksHolder .books .book {
  color: white;
  background: rgba(13, 24, 43, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.9px);
  -webkit-backdrop-filter: blur(7.9px);
  border: 1px solid rgba(108, 122, 148, 0.3);
  border-radius: 10px;
  padding: 2.5em 1.5em;
}
.ClientDash .infoHolder .booksHolder .books .book h1 {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 0;
}
.ClientDash .infoHolder .booksHolder .books .book p {
  margin: 1em 0;
  font-size: 1.1em;
}
.ClientDash .infoHolder .booksHolder .books .book a {
  background-color: #c1ff72;
  color: #0d182b;
  text-decoration: none;
  padding: 0.5em 1.5em;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  width: 150px;
  border-radius: 10px;
  text-align: center;
}
.ClientDash .form-container {
  width: 100%;
  max-width: 500px;
  min-width: 450px;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px;
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-image: linear-gradient(19deg, rgba(13, 24, 43, 0.6901960784) 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
  border-radius: 10px;
  padding: 2em 2em;
  position: relative;
}
@media screen and (max-width: 800px) {
  .ClientDash .form-container {
    min-width: auto;
    padding: 1.5em;
  }
}
.ClientDash .form-container form {
  width: 100%;
  position: relative;
}
.ClientDash .form-container form .sendBtn {
  width: 100%;
  outline: none;
  background-color: #c1ff72;
  border: none;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.3em;
  cursor: pointer;
}
.ClientDash .form-container form .innerFormHolder {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ClientDash .form-container form .innerFormHolder .fieldGroup {
  border-top: 1px solid white;
  padding-top: 1em;
  margin-top: 1em;
}
.ClientDash .form-container form .innerFormHolder .fieldGroup > div {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.ClientDash .form-container form .innerFormHolder .fieldGroup > div .field {
  width: 50%;
  flex-grow: 1;
  min-width: 50px;
}
.ClientDash .form-container form .innerFormHolder .RamadanSection {
  position: relative;
}
.ClientDash .form-container form .innerFormHolder .RamadanSection > img {
  position: relative;
  width: 100%;
}
.ClientDash .form-container form .innerFormHolder .RamadanSection > div {
  display: block;
}
.ClientDash .form-container form .innerFormHolder .RamadanSection > div .field {
  width: 100%;
  max-width: auto;
}
.ClientDash .form-container form .innerFormHolder .RamadanSection > div .field > div > button > svg {
  color: white;
}
.ClientDash .form-container form .innerFormHolder .RamadanSection > div .field > div > input {
  flex-grow: 1;
}
.ClientDash .form-container form .innerFormHolder .rightSideForm {
  flex-grow: 1;
}
.ClientDash .form-container form .innerFormHolder .rightSideForm .field {
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 10px;
}
.ClientDash .form-container form .innerFormHolder .rightSideForm .field label {
  font-size: 1.5em;
  margin-bottom: 3px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.ClientDash .form-container form .innerFormHolder .rightSideForm .field label svg {
  color: #c1ff72;
}
@media screen and (max-width: 800px) {
  .ClientDash .form-container form .innerFormHolder .rightSideForm .field label {
    font-size: 1.2em;
  }
}
.ClientDash .form-container form .innerFormHolder .rightSideForm .field input, .ClientDash .form-container form .innerFormHolder .rightSideForm .field select {
  outline: none;
  border: none;
  font-size: 1.1em;
  padding: 0.8em 1em;
  border-radius: 10px;
  max-width: 700px;
}
@media screen and (max-width: 800px) {
  .ClientDash .form-container form .innerFormHolder .rightSideForm .field input, .ClientDash .form-container form .innerFormHolder .rightSideForm .field select {
    font-size: 1em;
  }
}
.ClientDash .form-container form .innerFormHolder .rightSideForm .field select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm {
  border-top: 0.5px solid white;
  padding-top: 1em;
  margin-top: 1em;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field audio::-webkit-media-controls-enclosure {
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field audio {
  width: 100%;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field input {
  width: 100%;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field label {
  color: white;
  font-size: 1.5em;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field label svg {
  color: #c1ff72;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field button {
  border-radius: 10px;
  outline: none;
  border: none;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field .chnageImageBtn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 1em;
  background-color: rgb(255, 86, 86);
  padding: 0.5em 0;
  cursor: pointer;
}
.ClientDash .form-container form .innerFormHolder .leftSideForm .field .changeAdhenBtn {
  font-size: 1em;
  background-color: rgb(255, 86, 86);
  padding: 0.5em 0;
  cursor: pointer;
  margin-top: 0em;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.AdminLogin {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0d182b;
  background: linear-gradient(rgb(6, 13, 26), rgba(0, 0, 0, 0.75)), url("https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg");
  background-size: cover;
  color: white;
  padding: 1em;
}
.AdminLogin svg {
  color: white;
}
@media screen and (max-width: 500px) {
  .AdminLogin svg {
    display: none;
  }
}
.AdminLogin h1 {
  font-size: 3em;
  font-weight: 900;
  margin-top: 10px;
  color: #c1ff72;
  display: flex;
  align-items: center;
  text-align: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
@media screen and (max-width: 500px) {
  .AdminLogin h1 {
    font-size: 2.3em;
  }
}
.AdminLogin h1 svg {
  font-size: 0.8em;
}
.AdminLogin form {
  width: 100%;
  max-width: 1100px;
  display: flex;
}
.AdminLogin form input {
  outline: none;
  font-size: 1.5em;
  padding: 0.8em 2em;
  flex-grow: 1;
  border: none;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
@media screen and (max-width: 500px) {
  .AdminLogin form input {
    font-size: 1em;
    padding: 0.8em;
    padding-right: 1.5em;
  }
}
.AdminLogin form button {
  outline: none;
  font-size: 1.5em;
  padding: 0.8em 2em;
  border: none;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: #c1ff72;
  color: #0d182b;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .AdminLogin form button {
    font-size: 1em;
    padding: 0.5em 1em;
  }
}

.AdminDash {
  width: 100%;
  position: relative;
}
@media screen and (max-width: 900px) {
  .AdminDash header {
    font-size: 0.85em;
    padding: 0.8em 1em;
  }
}
.AdminDash .g2 {
  width: 100%;
  display: flex;
  margin-top: 10px;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
}
@media screen and (max-width: 900px) {
  .AdminDash .g2 {
    flex-direction: column;
  }
}
.AdminDash .g2 .MasajedCount {
  flex-grow: 0.5;
  background: linear-gradient(rgb(6, 13, 26), rgba(0, 0, 0, 0.5)), url("https://images.pexels.com/photos/3624566/pexels-photo-3624566.jpeg");
  background-size: cover;
  background-position: 50%;
  min-width: 300px;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.5em;
  -moz-column-gap: 3px;
       column-gap: 3px;
  font-weight: 900;
}
@media screen and (max-width: 900px) {
  .AdminDash .g2 .MasajedCount {
    font-size: 2.8em;
    padding: 1em 0.5em;
    min-width: auto;
  }
}
.AdminDash .g2 .MasajedCount svg {
  padding-bottom: 0.1em;
}
.AdminDash .g2 .addUser {
  width: 100%;
  border-radius: 10px;
  padding: 3em 2em;
  background-image: linear-gradient(19deg, rgba(13, 24, 43, 0.6901960784) 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
}
@media screen and (max-width: 900px) {
  .AdminDash .g2 .addUser {
    padding: 2em 1em;
  }
}
.AdminDash .g2 .addUser h2 {
  margin: 0;
  color: white;
  text-align: center;
  font-size: 3.5em;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .AdminDash .g2 .addUser h2 {
    font-size: 1.5em;
  }
  .AdminDash .g2 .addUser h2 svg {
    display: none;
  }
}
.AdminDash .g2 .addUser form {
  width: 100%;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
  justify-content: space-around;
  flex-wrap: wrap;
}
.AdminDash .g2 .addUser form input {
  flex-grow: 1;
  outline: none;
  border: none;
  padding: 0.8em 1em;
  font-size: 1em;
  border-radius: 10px;
  min-width: 250px;
}
@media screen and (max-width: 900px) {
  .AdminDash .g2 .addUser form input {
    min-width: auto;
    font-size: 0.9em;
  }
}
.AdminDash .g2 .addUser form button {
  width: 100%;
  outline: none;
  border: none;
  font-size: 1em;
  background-color: #c1ff72;
  color: #0d182b;
  padding: 0.8em 1em;
  border-radius: 10px;
  cursor: pointer;
}
.AdminDash .masajedHolder {
  width: 100%;
  margin-top: 10px;
  background-image: linear-gradient(19deg, rgba(13, 24, 43, 0.6901960784) 0%, #0d182b 95%), radial-gradient(75% 87% at 66% 75%, #06ffff 0%, rgb(20, 255, 177) 100%);
  border-radius: 10px;
  padding: 2em;
  position: relative;
}
@media screen and (max-width: 900px) {
  .AdminDash .masajedHolder {
    padding: 1em;
  }
}
.AdminDash .masajedHolder h2 {
  display: flex;
  justify-content: center;
  font-size: 4em;
  color: white;
  margin-bottom: 0;
  margin-top: 15px;
}
@media screen and (max-width: 900px) {
  .AdminDash .masajedHolder h2 {
    margin-top: 0;
    font-size: 3em;
  }
}
.AdminDash .masajedHolder .search-container {
  width: 100%;
  display: flex;
  background-color: white;
  align-items: center;
  padding-left: 1em;
  border-radius: 10px;
  position: relative;
}
.AdminDash .masajedHolder .search-container svg {
  font-size: 1.8em;
  color: #0d182b;
}
.AdminDash .masajedHolder .search-container input {
  flex-grow: 1;
  outline: none;
  border: none;
  font-size: 1.5em;
  padding: 0.5em 1em;
  border-radius: 10px;
}
@media screen and (max-width: 900px) {
  .AdminDash .masajedHolder .search-container input {
    font-size: 1em;
    padding: 0.8em 1.5em;
  }
  .AdminDash .masajedHolder .search-container input svg {
    font-size: 1em;
  }
}
.AdminDash .masajedHolder .innerMasajedHolder {
  width: 100%;
}
.AdminDash .masajedHolder .innerMasajedHolder ul {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
  list-style: none;
  padding: 0;
}
.AdminDash .masajedHolder .innerMasajedHolder ul li {
  flex-grow: 1;
  background-color: white;
  padding: 0.8em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  width: 300px;
}
.AdminDash .masajedHolder .innerMasajedHolder ul li span {
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.AdminDash .masajedHolder .innerMasajedHolder ul li span svg {
  color: #0d182b;
}
.AdminDash .masajedHolder .innerMasajedHolder ul li svg {
  color: #0d182b;
}

.delete-confirm-popup {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 24, 43, 0.9450980392);
}
.delete-confirm-popup p {
  font-size: 4em;
  color: white;
  font-weight: 900;
  margin-bottom: 20px;
}
.delete-confirm-popup div {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.delete-confirm-popup div button {
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 0.8em 1em;
  font-size: 1.5em;
  width: 150px;
  cursor: pointer;
}
.delete-confirm-popup div button:nth-child(1) {
  background-color: #c1ff72;
}
.delete-confirm-popup div button:nth-child(2) {
  background-color: rgb(255, 177, 177);
}/*# sourceMappingURL=main.css.map */