.ClientLogin{
    width: 100%;
    height: 100vh;
    display: flex;
    column-gap: 10px;
    padding: 1em;
    background: linear-gradient(rgb(6, 13, 26), rgba(0, 0, 0, 0.75)), url("https://images.pexels.com/photos/908055/pexels-photo-908055.jpeg");
    background-size: cover;
    section , .rightClientLogin {
        flex-grow: 1;
    }
    section{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 4em;
        position: relative;
        .backBtn {
            position: absolute;
            top: 1em;
            left: 1em;
            background-color: aliceblue;
            font-size: .85em;
            text-align: center;
            text-decoration: none;
            padding: .5em 2em;
            color: $main;
            border-radius: 15px;
            display: flex;
            align-items: center;
            transition: background-color .3s ease;
            &:hover{
                background-color: $sub;
            }
            svg{
                font-size: 1.7em;
            }
            @media screen and (max-width:500px){
                left: 0em;
                top: 0em;
            }
        }
        img{
            width: 210px;
            @media screen and (max-width:500px){
                width: 150px;
                margin-top: 2.5em;
            }
        }
        h2{
            font-size: 2.3em;
            color: white;
            margin-bottom: 20px;
            @media screen and (max-width:500px){
                font-size: 1.5em;
                text-align: center;
            }
        }
        form{
            width: 100%;
            max-width: 500px;
            button{
                width: 100%;
                padding: .8em;
                border-radius: 15px;
                outline: none;
                border: none;
                background-color: white;
                font-size: 1em;
                color: $main;
                cursor: pointer;
                margin-top: 20px;
                transition: background-color .3s ease;
                &:hover{
                    background-color: $sub;
                    color: $main;
                }
                @media screen and (max-width:500px){
                    font-size: 1em;
                    
                }
            }
            div{
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                label{
                    font-size: 1.5em;
                    color: white;
                    margin-bottom: 5px;
                    @media screen and (max-width:500px){
                        font-size: 1em;
                        
                    }
                }
                input{
                    outline: none;
                    padding: .8em 1em;
                    border-radius: 15px;
                    border: 1px solid $main;
                    font-size: 1em;
                }
            }
        }
    }
    .rightClientLogin{
        background:linear-gradient(rgb(20, 43, 85), rgba(0, 0, 0, 0.38)), url("https://images.pexels.com/photos/2349168/pexels-photo-2349168.jpeg");
        background-size: cover;
        border-radius: 20px;
        min-width: 40%;
        max-width: 600px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        display: flex;
        justify-content: left;
        align-items: start;
        padding: 1em;
        @media screen and (max-width:500px){
            display: none;
        }
        a{
            background-color: aliceblue;
            font-size: 1em;
            text-align: center;
            text-decoration: none;
            padding: .5em 2em;
            color: $main;
            border-radius: 15px;
            display: flex;
            align-items: center;
            transition: background-color .3s ease;
            &:hover{
                background-color: $sub;
            }
            svg{
                font-size: 1.7em;
            }
        }
    }
}